<template>
  <div class="indentBox">
    <div class="indentone" v-if="nowList.length!=0">
      <div >
        <p v-for="(item,index) in nowList" :key="index">
          <el-divider v-if="index!=0" direction="vertical" ></el-divider>
          <span class="title" :class="item.id === nowID?'nowTitle':''" @click="goPage(item)">
            {{ item.title  }}
          </span>
        </p>
      </div>
      <p class="NumCla">{{ totalText }}</p>
    </div>

    <div class="below">
      <ul class="left">
        <slot></slot>
      </ul>
      <p class="right">
        <el-button type="primary" size="small" @click="searchBtn" :disabled="searhLoading" :loading="searhLoading">
          搜索
        </el-button>
        <el-button
          type="success"
          size="small"
          @click="exportFun"
          :disabled="exportLoading" :loading="exportLoading"
          v-if="isExport"
        >
          导出
        </el-button>
      </p>
    </div>
  </div>
</template>

<script>
import { getReport } from '@/router/menuMain.js'
export default {
  props: {
    totalText: {
      type: String,
      default: '',
    },
    titleData: {
      type: Object
    },
    isExport: {
      type: Boolean,
      default: false
    },
    searhLoading: {
      type: Boolean,
      default: false
    },
    exportLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      statisticsList: [
        {
          permission_flag: 'Dosage',
          list: [
            {
              id: 1,
              title: '省份',
              path: '/Dosage',
            },
            {
              id: 2,
              title: '医院',
              path: '/DosageHospital',
            },
            {
              id: 3,
              title: '仪器',
              path: '/Device',
            },
          ]
        },
        {
          permission_flag: 'Sales',
          list: [
            {
              id: 1,
              title: '省份',
              path: '/Sales',
            },
            {
              id: 2,
              title: '经销商',
              path: '/Agency',
            },
          
            {
              id: 4,
              title: '日成交量',
              path: '/Turnover',
            },
          ]
        },
        {
          permission_flag: 'Maintain',
          list: [
            {
              id: 1,
              title: '省份',
              path: '/Maintain',
            },
            {
              id: 2,
              title: '医院',
              path: '/GoAgency',
            },
            {
              id: 3,
              title: '仪器',
              path: '/GoVolume',
            },
          ]
        },
        {
          permission_flag: 'SparePart',
          list: []
        },
        {
          permission_flag: 'Samples',
          list: []
        }
      ],
      nowID: null,
      nowList: [
      ],
      Report: false
    }
  },
  created() {
    this.Report = getReport();
    if(this.Report){
      this.statisticsList[1].list = [ {
              id: 1,
              title: '省份',
              path: '/Sales',
            },
            {
              id: 2,
              title: '经销商',
              path: '/Agency',
            },
            {
              id: 3,
              title: '报告',
              path: '/Volume',
            },
            {
              id: 4,
              title: '日成交量',
              path: '/Turnover',
            },]
    }
    this.nowList = this.statisticsList.find(e => e.permission_flag == this.titleData.permission_flag).list
    this.nowID = this.titleData.nowID
  },
  methods: {
    searchBtn() {
      this.$emit('searchBtn')
    },
    exportFun() {
      this.$emit('exportFun')
    },
    goPage(item) {
      if(item.id != this.nowID){
        this.$router.push({ path: item.path})
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.indentBox {
  background: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
  border-radius: 5px;
  margin-bottom: px(20);
  padding:  px(15);
}
.indentone {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d0d7e0;
  padding: px(15) 0; 
  &>div {
    display: flex;
    justify-content: flex-start;
    .title {
      font-weight: bold;
      color: #666666;
      margin:0 px(10);
      cursor: pointer;
    }
    .nowTitle {
      color: #111111;
    }
  }
  .NumCla {
    font-weight: bold;
    color: #111111;
  }
}
.below {
  display: flex;
  justify-content: space-between;
  padding: px(10) 0;
  .left {
    display: flex;
    flex-wrap: wrap;
    width: 80%;
    &>li {
      margin: px(10) px(10) 0 0;
    }
  }
  .right {
    width: 20%;
    display: flex;
    align-items: end;
    justify-content: center;
  }
}
</style>